import { observable, action, computed } from "mobx";

import openIDB from "../IDB";
import { pushDataLayer } from "../helpers/pushDataLayer"
import { RootModel } from "./RootStore"
import ArticleCacheClient, { checkArticleCache } from "../workers/ArticleCache/ArticleCacheClient";

import { IS_FEATURE_TIP } from "../components/common/constants/constants";

const dbPromise = openIDB();

export type SlateArticleType = Omit<ArticleType, "display_type"> & {
    display_type: "ARTICLE_SLATE";
    BodyPart: any[]
    author?: any;
}

export type SimilarArticlesType = {
    //TODO picture otypowac osobno bo wystepuje w kilku wersjach
    picture: {
        figcaption: string;
        img: string;
        source: string;
    };
    promolink?: boolean;
    title: string;
    url: string;
}

export type AuthorType = {
    img?: string;
    name: string;
    url?: string,
    email?: string,
    categoryName?: string,
    articlesCount?: number,
    articleDescription?: string,
    profession?: string,
    canFollow?: boolean,
    OID?: string;
    isRecentlyActive?: boolean,
}

export type TopicsType = {
    name: string;
    slug: string;
    url: string;
}

type Comment = {
    created_time: string;
    id: string;
    message: string;
}

export type CommentsData = {
    Count: number;
    Data: Comment[] | [];
}

export type LoadArticlesData = {
    OID: string;
    author: {
        fb: string;
        google_plus: string;
        name: string;
        twitter: string;
        url: string;
    };
    category: {
        OID: string;
        name: string;
        seo_title: string;
        show_category_title: string;
        slug: string;
        type: string;
        url: string;
    }
    date: string;
    display_type: "ARTICLE" | "RICH_ARTICLE" | "BRID" | "ARTICLE_SLATE";
    fb_url: string;
    iso_date: string;
    lead: string;
    picture: any;
    seo_title: string;
    slug: string;
    title: string;
    url: string;
    uri: string;
}

export interface IFetchedArticle {
    DATA_LAYER?: {
        articleCharacterRange?: string,
        articleAuthor?: string
    }
    DATA_GENERATED?: number;
    COMMENTS?: CommentsData;
    GA_AUTHOR_GROUP_INDEX?: number;
    GA_AUTHOR_GROUP_NAME?: string;
    GA_GROUP_INDEX?: number;
    GA_GROUP_NAME?: string;
    article: {} | ArticleType;
    //TODO - articles potrzebne?
    articles?: any;
    bridFooterOID?: string;
    data?: string;
    error?: number;
    error_desc?: string;
    jsonpCallback?: string;
    loadArticles?: Array<string>;
    loadArticlesData?: Array<LoadArticlesData>;
    similar_articles?: Array<SimilarArticlesType>;
    nextArticleInCategory?: any;
    topics: Array<TopicsType>;
    page_parameters?: {
        adultGate: boolean;
        adKeywords: {
            [key: string]: string;
        }
    }
}


class ArticleStore {
    RootStore: RootModel;
    constructor(RootStore: RootModel) {
        this.RootStore = RootStore;

        //Run cache worker client instance
        ArticleCacheClient(this);
    }

    @observable renderedArticleOID: string | null = null;
    currentSlug: string | null = null;

    @observable trudatVote: boolean | null = null;
    @observable fetchedArticle: IFetchedArticle = { article: {}, topics: [] };
    @observable bridRecommendedTiles = [];

    @computed
    get article() {
        /**
         * @codereview
         * powinno zwrócić pusty obiekt, a nie nulla
         */
        if (this.fetchedArticle) {
            return this.fetchedArticle.article;
        }
        return this.fetchedArticle;
    }

    @computed
    get bridOID() {
        return this.fetchedArticle?.bridFooterOID
    }

    @computed
    get similar_articles(): IFetchedArticle | Array<SimilarArticlesType> | undefined {
        /*
           * @codereview powinno zwrócić array, a nie nulla jak nie mamy artykułu
           */
        if (this.fetchedArticle) return this.fetchedArticle.similar_articles;
        return this.fetchedArticle;
    }

    @computed
    get loadArticles(): Array<string> | undefined {
        if (this.fetchedArticle) return this.fetchedArticle.loadArticles;
        return this.loadArticles;
    }

    @computed
    get loadArticlesData(): Array<LoadArticlesData> | undefined {
        if (this.fetchedArticle) return this.fetchedArticle.loadArticlesData;
        return this.loadArticlesData;
    }


    @computed
    get comments(): CommentsData | undefined {
        return this.fetchedArticle.COMMENTS
    }

    @action("Display Article")
    displayArticle = (fetchedArticle: IFetchedArticle): void => {
        const { UIStatus, changeUIType, updateGoogleAdKeywords, trackPageView } = this.RootStore.UIStore;

        const article = fetchedArticle.article as ArticleType;

        const gak = (fetchedArticle.article as ArticleType).google_ad_keywords || {};
        const topics = (gak.topics && gak.topics?.length > 0) ? gak.topics : [];
        const articleOID = article.OID || null;

        document.title = article.seo_title;

        //Load metadata only if the article is not empty and rendered OID is different
        const shouldLoadMetadata = articleOID && this.renderedArticleOID != articleOID;

        if (shouldLoadMetadata) {
            pushDataLayer({
                'event': 'virtualPageview',
                'virtualPageURL': article.uri,
                'virtualPageTitle': article.seo_title,
                'pageType': 'Artykuł',
                'articleSection': article.category.name,
                'articleTag': fetchedArticle.topics.length > 0 ? fetchedArticle.topics[0].name : "",
                'articleAuthor': fetchedArticle?.DATA_LAYER?.articleAuthor,
                'articleCharacterRange': fetchedArticle?.DATA_LAYER?.articleCharacterRange
            });

            updateGoogleAdKeywords({
                ...fetchedArticle?.page_parameters?.adKeywords,
                "article_id": gak.article_id,
                "category": gak.category,
                "topics": topics,
                "url": [article.uri]
            })

            trackPageView({
                'title': article.seo_title,
                'groupName': fetchedArticle.GA_GROUP_NAME,
                'authorGroupName': fetchedArticle.GA_AUTHOR_GROUP_NAME,
                'topics': topics
            });

            // upscore
            const upScoreData = {
                data: {
                    section: article?.category?.slug,
                    taxonomy: topics.join(','),
                    object_id: article.OID,
                    pubdate: article.iso_date,
                    author: fetchedArticle.GA_AUTHOR_GROUP_NAME,
                    object_type: 'article',
                    content_type: 0
                }
            };
            // console.log('upscore', upScoreData);
            if (window.upScore)
                window.upScore(upScoreData);

            //Send event
            document.dispatchEvent(new CustomEvent('article-render'));
            //Set rendered article OID
            this.renderedArticleOID = articleOID;
        }

        this.fetchedArticle = fetchedArticle;
        UIStatus.status = "valid";
        changeUIType(article.category);
    };

    @action("Update TRUDAT vote in this article")
    updateTrudatVote = (vote: boolean): void => {
        this.trudatVote = vote;
    };

    /**
     * @codereview czemu są 2 funkcje do pobierania body artykułu?
     */
    @action("Update article's body part")
    updateBodyPart = () => {
        const slug = this.currentSlug;
        if (!slug) {
            return;
        }

        const getParams = (window.location.search || '?');
        const timestamp = Math.round(Date.now() / 5000); // zamieniam na sekundy, bo inaczej nam cache nie wydoli
        const fetchUrl = `${window.API_HOST}/pwa-article/${slug}${getParams}&_t=${timestamp}`;
        fetch(fetchUrl, { cache: 'no-store' })
            .then((response: any) => {
                return response.ok
                    ? response.json()
                    : Promise.reject(response.status);
            })
            .then(action((res: any) => {
                this.displayArticle(res);
            }))
            .catch((err: any) => {
                window.APP_TRACK_ERROR("ArticleStore-fetchFromNetwork-updateBodyPart", { url: fetchUrl, error: err });
            })
    }

    @action("Get article based on current URL")
    updateSlug = (slug: string): void => {
        this.trudatVote = null;
        const { UIStatus } = this.RootStore.UIStore;
        const that = this;
        const currentId = parseInt(slug.split(',')[0]);

        that.currentSlug = slug;

        /*
        dbPromise
            .then(function (db: any) {
                const articleDbStore = db
                    .transaction("articles")
                    .objectStore("articles");
                return articleDbStore.get(slug);
            })
            .then(function (article: any) {
                if (article === undefined)
                    return Promise.reject();

                // console.log('[ES] article from db', article);
                that.displayArticle(article);
            })
            .catch(function () {
                UIStatus.status = "loading";
            });
        */

        const saveToDb = (networkResponse: any) => {
            dbPromise
                .then(function (db: any) {
                    var tx = db.transaction("articles", "readwrite");
                    var articlesDbStore = tx.objectStore("articles");
                    articlesDbStore.put(networkResponse, networkResponse.article.slug);
                    return tx.complete;
                })
                .then(function () {
                    // console.log(`Updated "${networkResponse.article.slug}" in articles`);
                });
        };

        const parseResponse = (res: any) => {
            this.displayArticle(res);
        }

        const fetchFromNetwork = async ({ slug, justUpdate = false }: { slug: string, justUpdate: boolean }) => {
            // parametry potrzebne np. do ticketów
            const getParams = new URLSearchParams(window.location.search);
            // na produkcji część parametrów GET jest wycinanych żeby cache się nie dublowało. to samo trzeba zrobić tutaj
            getParams.delete('utm_campaign');
            getParams.delete('utm_medium');
            getParams.delete('fbclid');
            // console.log(`[ES] fetchUri getParams ${getParams}`, getParams);

            const fetchUri = `/pwa-article/${slug}?${getParams}`;
            const fetchUrl = `${window.API_HOST}${fetchUri}`;

            console.log('[ES] fetchUri', fetchUri);
            if (window?.APP_SETTINGS?.pwa_preload && window?.APP_SETTINGS?.pwa_preload[fetchUri]) {
                console.log('[ES] pwa_preload');

                const PWA_articleData = window?.APP_SETTINGS?.pwa_preload[fetchUri];
                parseResponse(PWA_articleData);

                if (PWA_articleData.isCompleteDataPackage)
                    return;

            } else if (currentId && window?.APP_SETTINGS?.post_serialized && window?.APP_SETTINGS?.post_serialized[currentId]) {
                console.log('[ES] post_serialized');
                this.displayArticle({
                    DATA_LAYER: {},
                    article: window.APP_SETTINGS?.post_serialized[currentId],
                    loadArticles: [],
                    loadArticlesData: [],
                    similar_articles: [],
                    topics: [],
                });
            } else {
                const checkedArticle = await checkArticleCache(slug);
                if (checkedArticle && checkedArticle.available) {
                    console.log('[ES] fetch from cache');
                } else {
                    console.log('[ES] fetch');
                    UIStatus.status = "loading";
                }
            }

            fetch(fetchUrl)
                .then((response: any) => {
                    return response.ok
                        ? response.json()
                        : Promise.reject(response.status);
                })
                .then((res: any) => {
                    // console.log('[ES] article remote', response);
                    parseResponse(res);
                })
                .catch((err: any) => {
                    window.APP_TRACK_ERROR("ArticleStore-fetchFromNetwork", { url: fetchUrl, error: err });

                    if (err === 404) {
                        that.RootStore.UIStore.UIStatus = {
                            status: "error",
                            message: "404",
                        };
                        return;
                    } else if (err === 403) {
                        that.RootStore.UIStore.UIStatus = {
                            status: "error",
                            message: "403",
                        };
                        return;
                    } else if (err === 500) {
                        that.RootStore.UIStore.UIStatus = {
                            status: "error",
                            message: "500",
                        };
                        return;
                    } else if (!window.navigator.onLine && justUpdate !== true) {
                        that.RootStore.UIStore.UIStatus = {
                            status: "error",
                            message:
                                "Brak połączenia z internetem. Nadal możesz korzystać z aplikacji, choć część treści, tak jak ta, będzie niedostępna"
                        };
                        return;
                    } else if (justUpdate) {
                        return;
                    } else {
                        that.RootStore.UIStore.UIStatus = {
                            status: "error",
                            message: "Ups! Wystąpił problem podczas pobierania artykułu. Sprawdź połączenie z internetem i spróbuj ponownie"
                        };
                        return;
                    }
                });

        };

        fetchFromNetwork({ slug: slug, justUpdate: true });
    };

    @action
    fetchBridCategory = (slug: string): void => {
        const url = `${window.API_HOST}/pwa-category/${slug}`
        fetch(url, { cache: 'no-store' })
            .then((res: any) => {
                return res.ok
                    ? res.json()
                    : Promise.reject(res.status);
            })
            .then(action((res: any) => {
                this.bridRecommendedTiles = res.category.wizard
            }))
            .catch((error: any) => {
                window.APP_TRACK_ERROR("ArticleStore-fetchFromNetwork", { url, error });
            })
    }
}

export default ArticleStore;
